import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pla-new-search-input',
  templateUrl: './new-search-input.component.html',
  styleUrls: ['./new-search-input.component.scss'],
})
export class NewSearchInputComponent implements OnInit {
  @Input('searchTrigger') public searchTrigger: EventEmitter<void> =
    new EventEmitter<void>();
  @Input() public defaultInput: string = '';
  @Input() public route: string = 'search-results/'
  public focused: boolean = false;
  public searchInput: string;
  @Input() public placeholder: string = 'What can we find for you?';
  @Input() public searchTexts: string[] = [
    'Name',
    'Initials',
    'Place',
    'Team',
    'Date',
    'Vehicle',
    'Brand'
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.searchInput = this.defaultInput.toUpperCase();
    this.searchTrigger.subscribe(() => {
      this.router.navigate([`${this.route}${this.searchInput}`]);
    });
  }

  public EnterSubmit(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.stopImmediatePropagation();
      this.router.navigate([`${this.route}${this.searchInput}`]);
    }
  }

  public FocusElement(): void {
    document.getElementById('search-input').focus();
  }

  public SetFocus(value: boolean): void {
    this.focused = value;
  }
}
