import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CompareResult } from 'src/app/compare-result';
import { UserDetails } from 'src/app/models/userDetails';
import { CompareService } from 'src/app/services/compare-service';
import { SessionService } from 'src/app/services/session-service';
import { TrackingService } from 'src/app/services/tracking-service';
import { UserService } from 'src/app/services/user-service';
import {
  DropdownConfig,
  DropdownOption,
} from '../custom-dropdown/custom-dropdown.component';
import {
  SidebarFilter,
  SidebarFilterConfig,
  SidebarFilterGroup,
} from '../side-bar-filters/side-bar-filters.component';
import { CurrencyPipe } from '@angular/common';
import { RegistrationService } from 'src/app/services/registration-service';
import { PlatePaymentDetails } from 'src/app/models/platePaymentDetails';

declare global {
  interface Window {
    gtag: any;
    tp: any;
  }
}

export interface CompareFilters {
  standardPlates: boolean;
  managedTransfer: boolean;

  offersBespokeFinance: boolean;
  offersKlarna: boolean;

  five_star: boolean;
  four_star: boolean;
  three_star: boolean;
  all_ratings: boolean;
}

@Component({
  selector: 'pla-tp-sellers',
  templateUrl: './tp-sellers.component.html',
  styleUrls: ['./tp-sellers.component.scss'],
})
export class TpSellersComponent implements OnInit {

  public iconClasses = `mid mb-large fw-bold d-block mx-0 mx-md-auto text-center`;

  @Input() public registration: string;
  @Input() public useCachedResults: boolean = false;
  @Output() public resultsChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public PriceRangeChange = new EventEmitter<{
    min: number;
    max: number;
  }>();
  @Output() public compareFilters = new EventEmitter<
    SidebarFilterConfig<CompareFilters>
  >();
  public compareFilterValues: CompareFilters;
  public mobileFilters: boolean = false;
  public filterConfig: SidebarFilterConfig<CompareFilters> =
    new SidebarFilterConfig<CompareFilters>(
      [
        new SidebarFilterGroup('Include', [
          new SidebarFilter('checkbox', 'Standard Plates', 'standardPlates'),
          new SidebarFilter('checkbox', 'Managed Transfer', 'managedTransfer'),
        ]),
        new SidebarFilterGroup('Finance', [
          new SidebarFilter('checkbox', 'Klarna', 'offersKlarna'),
          new SidebarFilter('checkbox', 'Bespoke Finance', 'offersBespokeFinance'),
        ]),
        new SidebarFilterGroup('Rating', [
          new SidebarFilter('checkbox', '5 Star', 'five_star'),
          new SidebarFilter('checkbox', '4 Star +', 'four_star'),
          new SidebarFilter('checkbox', '3 Star +', 'three_star'),
        ]),
      ],
      new EventEmitter<CompareFilters>()
    );

  public viewResults: CompareResult[] = [];
  public selectedCompareResult: CompareResult;
  public compareResults: CompareResult[] = [];
  public resultsCanShow: boolean = false;

  public withTransfer: boolean = false;
  public withPerspex: boolean = false;
  public transferOnly: boolean = false;
  public financeOnly: boolean = false;
  public sortConfig = new DropdownConfig('Sort', 'sort', [
    new DropdownOption('lowest-price', 'Lowest Price'),
    new DropdownOption('highest-price', 'Highest Price'),
  ]);
  public seeAll: boolean = false;
  public sort: string = 'lowest-price';
  public loading: boolean = true;
  public searchAlmostComplete: EventEmitter<void> = new EventEmitter<void>();
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public mobileRowToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;

  constructor(
    private registrationService: RegistrationService,
    private compareService: CompareService,
    private trackingService: TrackingService,
    private sessionService: SessionService,
    private userService: UserService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.SetupFilters();
    this.Compare();
  }

  public visibleResults(): number {
    /*SHOW ALL + 1 unfaded W/ 9*/
    const availableResults = this.viewResults.filter(r => r.price > 0).length;
    if (availableResults >= 9) return 9;
    if (availableResults == 0) return 3;
    return availableResults + 1
  }

  public SetupFilters(): void {
    this.filterConfig.onChange.subscribe((change: CompareFilters) => {
      this.compareFilterValues = change;
      this.withTransfer = change.managedTransfer;
      this.transferOnly = change.managedTransfer;
      this.withPerspex = change.standardPlates;
      this.CreateLists();
    });
    this.compareFilters.emit(this.filterConfig);
  }

  public ToggleMobileFilters(visible: boolean): void {
    this.mobileFilterToggle.emit(visible);
  }

  public changeSort(change: DropdownOption): void {
    this.sort = change.value;
    this.CreateLists();
  }

  public SelectAllHandler(): void {
    this.seeAll = true;
  }

  public SelectMobileResult(result: CompareResult): void {
    this.selectedCompareResult = result;
    this.mobileRowToggle.emit(true)
  }

  private Compare(): void {
    if (this.registration == '' || this.registration == undefined) return;
    this.loading = true;
    this.seeAll = false;
    this.resultsCanShow = false;
    if (this.useCachedResults) {
      this.registrationService.getPlatePaymentDetails(this.registration.replace(' ', '')).subscribe((details: PlatePaymentDetails) => {
        this.searchAlmostComplete.emit();
        setTimeout(() => {
          this.compareResults = details.sellersResp.sellers;
          this.SetHistory(details.sellersResp.sellers);
          this.CreateLists();
          this.resultsChange.emit(true);
        }, 1000);
      });
    } else {
      this.compareService
        .getPlateComparison(this.registration.replace(' ', ''))
        .subscribe((r: CompareResult[]) => {
          this.searchAlmostComplete.emit();
          setTimeout(() => {
            this.compareResults = r;
            this.SetHistory(r);
            this.CreateLists();
            this.resultsChange.emit(true);
          }, 1000);
        });
    }
  }

  private SetHistory(results: CompareResult[]): void {
    var anyAvailable = results.filter(r => r.price > 0).length > 0;
    if (!anyAvailable) {
      this.userService.addCompareToHistory(this.registration, 'No Results');
    } else {
      var cheapest = results.filter(r => r.price > 0).sort(r => r.price).reverse()[0];
      this.userService.addCompareToHistory(this.registration, this.currencyPipe.transform(cheapest.price, 'GBP'));
    }
  }

  public CompleteLoad(): void {
    this.resultsCanShow = true;
  }

  public CreateLists(): void {
    var rawResults = JSON.parse(JSON.stringify(this.compareResults));
    var calculatedRows = this.compareService.calculateFoundRows(
      rawResults,
      this.withTransfer,
      this.withPerspex
    );

    // filter based on options
    if (this.compareFilterValues != undefined) {
      if (this.compareFilterValues.standardPlates) {
        calculatedRows = calculatedRows.filter(
          (cr) => cr.physicalPlatePrice > -1
        );
      }

      if (this.compareFilterValues.managedTransfer) {
        calculatedRows = calculatedRows.filter((cr) => cr.transferService > -1);
      }

      if (this.compareFilterValues.offersKlarna) {
        calculatedRows = calculatedRows.filter(
          (cr) =>
            cr.financeMethods != null &&
            cr.financeMethods.indexOf('klarna') > -1
        );
      }

      if (this.compareFilterValues.offersBespokeFinance) {
        calculatedRows = calculatedRows.filter((cr) => {
          if (cr.finance) return true;
          if (
            cr.financeMethods != null &&
            cr.financeMethods.indexOf('klarna') == -1
          )
            return true;
          return false;
        });
      }

      if (this.compareFilterValues.five_star)
        calculatedRows = calculatedRows.filter((cr) => {
          var trustPilotRating = Math.round(cr.trustPilotScore * 2) / 2;
          return trustPilotRating == 5;
        });

      if (this.compareFilterValues.four_star)
        calculatedRows = calculatedRows.filter((cr) => {
          var trustPilotRating = Math.round(cr.trustPilotScore * 2) / 2;
          return trustPilotRating >= 4;
        });

      if (this.compareFilterValues.three_star)
        calculatedRows = calculatedRows.filter((cr) => {
          var trustPilotRating = Math.round(cr.trustPilotScore * 2) / 2;
          return trustPilotRating >= 3;
        });
    }

    var foundCalculatedRows = [...calculatedRows]
      .filter((r) => r.calculated)
      .sort((a, b) => {
        if (this.sort === 'lowest-price') {
          return a.total - b.total;
        } else if (this.sort === 'highest-price') {
          return b.total - a.total;
        } else {
          return a.total - b.total;
        }
      });

    var notFoundResults = [...calculatedRows].filter(
      (r) => !r.calculated && r.status == 'NOT FOUND'
    );

    this.viewResults = foundCalculatedRows;
    this.viewResults.push(...notFoundResults);

    this.viewResults = this.viewResults.filter((vr) => {
      if (this.transferOnly) {
        if (vr.transferService < 0) return false;
      }

      if (this.financeOnly) {
        if (!vr.finance) return false;
      }

      return true;
    });

    const ___ = [...this.viewResults]
      .filter((r) => r.calculated && r.status !== 'NOT FOUND')
      .sort((a, b) => a.total - b.total);

    try {
      if (___.length > 0) {
        this.PriceRangeChange.emit({
          min: ___[0].total,
          max: ___[___.length - 1].total,
        });
      } else {
        this.PriceRangeChange.emit({ min: 0, max: 0 })
      }
    } catch {
      console.log('price range fail');
    }

    this.loading = false;
  }

  public hasAvailableResults(): boolean {
    return this.viewResults.filter(r => r.price > 0).length > 0;
  }

  public trustPilotScoreImage(result: CompareResult): string {
    if (result === undefined) return 'https://www.platex.co.uk/assets/logo.png';
    var trustPilotRating = Math.round(result.trustPilotScore * 2) / 2;
    return `https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${trustPilotRating}.svg`;
  }

  public openTPLink(result: CompareResult): void {
    window.open(result.trustPilotLink, '_blank');
  }

  public openCompareSiteHandler(result: CompareResult): void {
    window.gtag('event', 'page_view', {
      page_title: `Affiliate - ${this.registration.replace(' ', '')} (${result.viewName})`,
    });

    this.trackingService.TrackAffiliateDetail(
      this.registration,
      result.viewName,
      {
        dealer: result,
      }
    );

    if (this.sessionService.isLoggedIn()) {
      this.userService.getUserDetails((userDetails: UserDetails) => {
        const trustpilot_invitation = {
          recipientEmail: userDetails.email,
          recipientName: userDetails.firstName,
          referenceId: `PLA_${this.registration.replace(' ', '')}`,
          source: 'InvitationScript',
        };
        // window.tp('createInvitation', trustpilot_invitation);
      });
    }

    if (result.url.indexOf('?') == -1) {
      window.open(
        `${result.url}?token=${this.sessionService.getToken()}`,
        '_blank'
      );
    } else {
      window.open(
        `${result.url}&token=${this.sessionService.getToken()}`,
        '_blank'
      );
    }
  }
}
