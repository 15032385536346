<mat-drawer-container class="overflow-unset" style="overflow-x: initial !important">
  <mat-drawer #filterDrawer mode="over" position="start" style="
      width: 75vw;
      position: fixed;
      max-width: 210px;
      visibility: visible !important;
    " class="visible MOBILE-FILTERS-DRAWER" [autoFocus]="false">
    <div class="h-100 pb-3 bg-light">
      <div class="site-header-container fixed">
        <div class="spacer"></div>
        <div class="text-dark p-3 mb-4">
          <a class="text-decoration-none w-100 d-flex align-items-center justify-content-end text-dark"
            (click)="filterDrawer.toggle()">
            <div class="d-flex align-items-center justify-content-end">
              <mat-icon>close</mat-icon>
            </div>
          </a>
          <pla-side-bar-filters [filtersConfig]="searchFilters"></pla-side-bar-filters>

          <a class="text-decoration-none w-100 d-block align-items-center justify-content-end text-dark fw-normal fs-mb-18 mb-0 platex-border-button rounded-3 px-3 py-2 lh-1 text-center mt-5"
            (click)="filterDrawer.toggle()">
            APPLY
          </a>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content class="w-100 h-100 overflow-unset">
    <div class="page pt-2 pt-lg-4">
      <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
          <div class="pt-0 position-relative">
            <pla-side-nav>
              <div second class="bg-light text-dark p-3 mb-4">
                <pla-side-bar-filters [filtersConfig]="searchFilters"></pla-side-bar-filters>
              </div>

              <div fourth class="bg-light p-3 text-dark mb-4">
                <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                  [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
              </div>
            </pla-side-nav>
          </div>
        </div>
        <div class="center-content-container position-relative">
          <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
            <div>
              <!-- BREADCRUMBS -->
              <div class="w-100">
                <div
                  class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                  <a style="color: #327b8d; white-space: pre" class="text-decoration-none d-block"
                    [routerLink]="['/buy']">Buy</a>
                  <span>/</span>
                  <span style="color: #939393; white-space: pre" class="text-decoration-none d-block">{{ searchCriteria
                    | uppercase }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0">
            <!-- CONTENT -->

            <div class="pt-4 px-2">
              <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-center mb-1">
                SEARCH ANYTHING,<br />FIND EVERYTHING
              </p>
              <p class="lh-sm fw-light fs-md-18 fs-mb-16 text-center mb-2">
                Search all plates in one place
              </p>
            </div>

            <div>
              <div class="px-0 px-md-2 mt-4 w-100 mx-auto" style="max-width: 1000px">
                <div class="px-4 px-md-3">
                  <div class="w-100 mx-auto search-input">
                    <div class="mb-3 mx-auto rounded-2 overflow-hidden plate-background flat shaded position-relative">
                      <div class="mx-auto d-flex align-items-stretch justify-content-between py-2">
                        <div class="bg-transparent w-100 p-1">
                          <pla-new-search-input [defaultInput]="searchCriteria"
                            [searchTrigger]="searchTrigger"></pla-new-search-input>
                        </div>
                        <button (click)="searchTrigger.emit()" style="max-width: 56px"
                          class="w-100 fs-md-15 fs-mb-15 btn text-dark d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                          <mat-icon class="">search</mat-icon>
                        </button>
                      </div>
                      <!-- <div class="position-absolute bottom-0 w-100" *ngIf="true || superSearching">
                        <pla-search-loader [showText]="false" [classes]="'px-0'"
                          [almostComplete]="superSearchComplete"></pla-search-loader>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="alternativeOptions && alternativeOptions.length > 0" class="alt-search-options">
                <ng-container *ngFor="let option of alternativeOptions">
                  <div (click)="routeToNewSearch(option)" class="alt-search-option">
                    <div class="icon">
                      <mat-icon>search</mat-icon>
                    </div>
                    <span>{{ option.term }}</span>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- ALERT BANNER -->
            <div class="alert-box-container" *ngIf="false && searchCriteria != null && fetchedAlerts">
              <ng-container *ngIf="hasAlert(); else noAlert">
                <p>
                  <mat-icon> notifications_active </mat-icon>
                  You have alerts setup for this search.
                  <span class="white-text-button" (click)="addQuickAlert()">Cancel Alert</span>
                </p>
              </ng-container>

              <ng-template #noAlert>
                <pla-signed-up-content *ngIf="searchCriteria" [showButton]="false" class="d-block w-100"
                  [requesting]="'term alert ' + searchCriteria">
                  <div lockedContent>
                    <p class="d-block text-center text-white">
                      <mat-icon> notifications_none </mat-icon>
                      <span class="white-text-button text-white" (click)="addQuickAlert()">Alert Me</span>
                      when new "{{ searchCriteria | uppercase }}" plates are
                      added.
                    </p>
                  </div>
                  <div unlockedContent>
                    <p class="text-white">
                      <mat-icon> notifications_none </mat-icon>
                      <span class="white-text-button text-white" (click)="addQuickAlert()">Alert Me</span>
                      when new "{{ searchCriteria | uppercase }}" plates are
                      added.
                    </p>
                  </div>
                </pla-signed-up-content>
              </ng-template>
            </div>

            <div class="hs-spacing"></div>

            <!-- RECENT SEARCHES -->
            <div class="content-container extended position-relative border-bottom border-1">
              <div class="position-absolute" style="top: -54px" id="recent-searches"></div>
              <div class="w-100">
                <pla-user-history [type]="'search'" [title]="'Recent Searches'"></pla-user-history>
              </div>
            </div>


            <pla-sticky-tabs [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'"></pla-sticky-tabs>

            <div
              class="position-sticky start-0 end-0 bg-white shadow-sm align-items-center d-block d-lg-none border-bottom border-1"
              style="z-index: 51; top: 122px">
              <div class="d-flex justifty-content-center align-items-stretch flex-row">
                <a class="text-decoration-none w-100 d-flex d-lg-none align-items-center justify-content-center gap-2 py-2 text-dark px-2 pointer hover-interact"
                  (click)="filterDrawer.toggle()">
                  <span>Filter</span>
                  <mat-icon>tune</mat-icon>
                </a>

                <div class="flex-fill w-100 d-flex d-lg-none">
                  <pla-custom-dropdown class="d-block w-100" [closedClass]="" [containerClass]="" [openedClass]=""
                    [baseClass]="'border-start border-1'"
                    [optionClass]="'border-1 border border-top-0 border-start border-end border-bottom rounded-bottom-3 overflow-hidden'"
                    (change)="SortChange($event, 'sortBy')" [config]="sortDropdownConfig"></pla-custom-dropdown>
                </div>
              </div>
            </div>

            <div class="position-relative start-0 end-0 align-items-center d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-end pt-3">
                <div class="w-100" style="max-width: 200px;">
                  <pla-custom-dropdown [baseClass]="'bg-white border-1 border-dark'" [closedClass]="'rounded-3 border'"
                    [openedClass]="
                    'rounded-top-3 border-start border-top border-end border-bottom-transparent'
                  " [optionClass]="
                    'border-1 border border-dark border-start border-end border-bottom rounded-bottom-3 overflow-hidden'
                  " (change)="SortChange($event, 'sortBy')" [config]="sortDropdownConfig"></pla-custom-dropdown>
                </div>
              </div>
            </div>

            <div class="px-2 mt-2" *ngIf="superSearching">
              <div class="bg-light rounded-3 overflow-hidden border border-1">
                <pla-search-loader [classes]="'py-0'" [almostComplete]="superSearchComplete"></pla-search-loader>
              </div>
            </div>

            <div class="pt-3"></div>

            <div class="mt-3 w-100 position-relative">
              <div class="position-absolute" style="top: -170px" id="close-matches"></div>
            </div>

            <!-- PERFECT CARD -->
            <div *ngIf="
                !perfectSearching &&
                perfectResults &&
                perfectResults.registrations.length > 0
              " class="px-2">
              <div class="py-2 rounded-lg content-container extended" style="background: #F2FAF8">
                <div class="w-100 px-2 px-md-4">
                  <div class="perfect-container">
                    <pla-search-result-table [filtersChange]="filterChange" [searchTableType]="'perfect'"
                      [hideOnEmpty]="true" [searching]="perfectSearching" [searchTitle]="searchCriteria"
                      [searchType]="'PERFECT'" [resultType]="'PLATE'" [pluralResultType]="'PLATES'"
                      [results]="perfectResults" [resultChange]="perfectResultChange" [showFilter]="false"
                      [showSort]="false" [topResultSet]="true"></pla-search-result-table>
                  </div>
                </div>
              </div>

              <div class="hs-spacing"></div>
            </div>

            <!-- NEWLY LISTED CARD -->
            <div *ngIf="
                !superSearching &&
                newlyListedResults &&
                newlyListedResults.registrations.length > 0
              " class="px-2">
              <div class="py-2 rounded-lg content-container extended" style="background: #FFF7EB">
                <div class="w-100 px-2 px-md-4">
                  <div class="perfect-container">
                    <pla-search-result-table [filtersChange]="filterChange" [searchTableType]="'newly_listed'"
                      [hideOnEmpty]="true" [searchTitle]="searchCriteria" [searching]="superSearching"
                      [searchType]="'NEW'" [resultType]="'PLATE'" [pluralResultType]="'PLATES'"
                      [results]="newlyListedResults" [resultChange]="newlyListedResultChange" [showFilter]="false"
                      [showSort]="false" [topResultSet]="true"></pla-search-result-table>
                  </div>
                </div>
              </div>

              <div class="hs-spacing"></div>
            </div>

            <!-- <pla-page-placement [placement]="ownSimilarPlacement"></pla-page-placement> 
             <div class="hs-spacing"></div> -->

            <!-- MAIN SEARCH CARD -->
            <div class="content-container extended px-2">
              <div class="w-100 px-2 px-md-4">
                <pla-search-result-table [canRank]="true" [filtersChange]="filterChange" [searching]="superSearching"
                  [searchTitle]="searchCriteria" [searchTableType]="'main'" [searchType]="''" [hideOnEmpty]="true"
                  [resultType]="'CLOSE MATCHES'" [pluralResultType]="'CLOSE MATCHES'" [results]="results"
                  [showRatings]="true" [resultChange]="resultChange" [topResultSet]="false"></pla-search-result-table>
              </div>
            </div>

            <div class="hs-spacing"></div>

            <div class="px-2">
              <pla-page-placement [placement]="howDidWeDo"></pla-page-placement>
            </div>

            <div class="hs-spacing"></div>

            <div class="mt-3 w-100 position-relative">
              <div class="position-absolute" style="top: -170px" id="similar"></div>
            </div>

            <div class="content-container extended px-2">
              <div class="w-100 px-2 px-md-4">
                <!-- SYNONYM CARDS -->
                <ng-container *ngFor="let synonymResult of synonymResults">
                  <div *ngIf="synonymResults">
                    <pla-search-result-table [searchTableType]="'synonym'" [searching]="false"
                      [headerText]="generateSynonymHeaderText(synonymResult)" [results]="synonymResult.resp"
                      [showFilter]="false" [showSort]="false" [showRatings]="true" [topResultSet]="false"
                      [sortOnStart]="false"></pla-search-result-table>
                    <!-- <pla-see-more-button [term]="synonymResult.synonym" [align]="'right'" [padding]="true"></pla-see-more-button> -->
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="hs-spacing"></div>

            <div class="mt-3 w-100 position-relative">
              <div class="position-absolute" style="top: -170px" id="current-lists"></div>
            </div>

            <!-- CURRENT EXTENDED CARD -->
            <div class="content-container extended px-2" *ngIf="currentResults != undefined">
              <div class="w-100 px-2 px-md-4">
                <ng-container *ngFor="let currentResultSet of currentResults">
                  <pla-search-result-table [filtersChange]="filterChange" [searchTableType]="'current_extended'"
                    [hideOnEmpty]="true" [searchTitle]="currentResultSet.id" [searching]="false" [searchType]="''"
                    [resultType]="'PLATE'" [pluralResultType]="'PLATES'" [results]="currentResultSet"
                    [showFilter]="false" [showSort]="false" [paginationPageSize]="5"
                    [topResultSet]="true"></pla-search-result-table>

                  <div class="hs-spacing"></div>
                </ng-container>
              </div>
            </div>

            <div class="mt-3 w-100 position-relative">
              <div class="position-absolute" style="top: -170px" id="prefix-lists"></div>
            </div>

            <!-- PREFIX EXTENDED CARD -->
            <div class="content-container extended px-2" *ngIf="prefixResults != undefined">
              <div class="w-100 px-2 px-md-4">
                <ng-container *ngFor="let prefixResultSet of prefixResults">
                  <pla-search-result-table [filtersChange]="filterChange" [searchTableType]="'prefix_extended'"
                    [hideOnEmpty]="true" [searchTitle]="prefixResultSet.id" [searching]="false" [searchType]="''"
                    [resultType]="'PLATE'" [pluralResultType]="'PLATES'" [results]="prefixResultSet"
                    [showFilter]="false" [showSort]="false" [paginationPageSize]="5"
                    [topResultSet]="true"></pla-search-result-table>

                  <div class="hs-spacing"></div>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- BYO CARD -->
          <div class="pla_card no-card mobile spacing padding" *ngIf="false && byoTerm">
            <div>
              <h2 class="byo-title">Build-Your-Own</h2>
            </div>
            <pla-byo-search-page [defaultStyle]="'current'" [breakdowns]="byoTerm" [searchDefault]="true"
              [inlineSearch]="true"></pla-byo-search-page>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>