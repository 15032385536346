<div class="page" *ngIf="!inlineSearch">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block" [routerLink]="['/byo-search']">Build
                Your Own</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'RR_DEFENDER'" [rawImage]="true" [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'">
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height banner-tint">
            <!-- HEADER TEXT -->
            <div class="text-white text-center w-100 align-self-center justify-content-center">
              <p class="h1 mb-1 fw-bold">BUILD-YOUR-OWN</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Starting from £250
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-byo-search-input [showSwitch]="showSwitch" [defaultStyle]="style" [prefix]="activeBreakdown().prefix"
                [numbers]="activeBreakdown().numbers" [letters]="activeBreakdown().letters"
                (searchOutput)="doInlineSearch($event)" [padLeft]="false" [withPadding]="false"></pla-byo-search-input>
            </div>
          </div>
        </div>

      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <!-- CONTENT -->

        <!-- SEARCH RESULTS -->
        <div *ngIf="inlineSearch || hasSearchCriteria; else infoSection">
          <div [ngClass]="{
            'pla_card no-card mobile spacing padding': !inlineSearch
          }">
            <pla-search-result-table [searching]="loading" [searchTitle]="searchTerm" [searchType]="'plates'"
              [resultType]="''" [pluralResultType]="''" [showFilter]="false" [results]="results"
              [resultChange]="resultChange"></pla-search-result-table>
          </div>
        </div>

        <ng-template #infoSection>
          <div class="pla_card no-card mobile spacing padding">
            <div class="px-4">
              <div class="text-section">
                <div class="text-title heading">
                  <h2>Build-Your-Own</h2>
                </div>
                <div class="text-paragraph">
                  <p>
                    Build-Your-Own registration from either the
                    <pla-glossary-term-word [term]="'Current or New Style Registration'"
                      [text]="'Current'"></pla-glossary-term-word>
                    style (AA12 ABC),
                    <pla-glossary-term-word [term]="'Prefix Style Registration Plate'"
                      [text]="'Prefix'"></pla-glossary-term-word>
                    (A123 ABC) or
                    <pla-glossary-term-word [term]="'Suffix Style Registration Plate'"
                      [text]="'Suffix'"></pla-glossary-term-word>
                    (ABC 123A) registration.
                  </p>
                </div>
              </div>

              <div class="text-title heading mt-3">
                <h2 class="border-bottom mb-1">How to...</h2>
              </div>
              <div class="text-section">
                <div class="text-paragraph">
                  <div class="indent">
                    <p class="mb-1">
                      1. Use the tabs to pick a registration series.
                    </p>
                    <p class="mb-0">
                      2. Select the letters from the drop-down to make your word.
                    </p>
                    <p class="mb-0">
                      Note: Each series of registration must have numbers in set
                      locations, these are;
                    </p>
                    <p class="mb-0 ms-3">- 3rd and 4th for Current Style,</p>
                    <p class="mb-0 ms-3">
                      - Only 2nd, 2nd and 3rd or 2nd, 3rd and 4th for Prefix,
                    </p>
                    <p class="mb-0 ms-3">
                      - Only 4th, 4th and 5th / 4th, 5th and 6th for Suffix.
                    </p>
                  </div>
                  <div class="indent mt-1">
                    <p>
                      In many instances a number can be used to represent the
                      letters such as 5 for S, 4 for A and 7 for T.
                    </p>
                    <p>
                      Also note that when building a Current Series plate the
                      number represent a year code so availablity will depend on
                      whether the combination has been released.
                    </p>
                    <p>
                      For eaxmple if you wished to make the perfect plate 'BA77
                      ERY' this would require the number 77 which will not be
                      released until September 2027! However by entering 'Battery'
                      info our smart-search you can find other perfect plates from
                      other series such as the Prefix plate 'B477 ERY' and Suffix
                      plate 'BAT 732Y'.
                    </p>
                  </div>
                </div>
              </div>

              <div class="text-section mt-3">
                <div class="text-paragraph">
                  <div class="faq-item">
                    <mat-icon>help_outline</mat-icon>
                    <h4>The registration I tried to build is not there?</h4>
                  </div>
                  <p class="indent">
                    This could mean that the registration was not part of the
                    series or has already been sold. You can choose Notify in the
                    main menu and should it ever come back to the market you can
                    be informed!
                  </p>

                  <div class="faq-item">
                    <mat-icon>help_outline</mat-icon>
                    <h4>Can I put any of these registrations on my vehicle?</h4>
                  </div>
                  <p class="indent">
                    You are not allowed to make a vehicle appear younger by
                    transferring a more recent registration. Simply click on the
                    registration to see the minimum age of a vehicle needed for
                    that registration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>


      </div>
    </div>
  </div>
</div>

<div *ngIf="inlineSearch">
  <div class="-search-input-container">
    <div class="search-area inner">
      <div class="box-container">
        <pla-byo-search-input [showSwitch]="showSwitch" [defaultStyle]="style" [prefix]="activeBreakdown().prefix"
          [numbers]="activeBreakdown().numbers" [letters]="activeBreakdown().letters" [breakdowns]="styleBreakdowns"
          (searchOutput)="doInlineSearch($event)" [padLeft]="false" [withPadding]="false"></pla-byo-search-input>
      </div>
    </div>
  </div>
</div>