<div class="page">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/initials-search']">Initials</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'LAMBORGHINI_DIABLO'" [rawImage]="true" [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'">
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height banner-tint">
            <!-- HEADER TEXT -->
            <div class="text-white text-center w-100 align-self-center justify-content-center">
              <p class="h1 mb-1 fw-bold">INITIALS</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Search yours now
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-wildcard-character-select (inlineSearch)="doInlineSearch($event)" [boxCount]="3"
                [showMessage]="false" [letters]="initials" [style]="'any_text'" [withPadding]="false" [loading]="
                  dlf_searching ||
                  dnf_searching ||
                  current_searching ||
                  prefix_searching ||
                  suffix_searching
                " [validationRegex]="'[A-Za-z]+$'"></pla-wildcard-character-select>
              <span class="header">Search up to 3 initials</span>
            </div>
          </div>
        </div>

      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <!-- CONTENT -->

        <!-- RESULTS -->
        <div *ngIf="
      initialResults ||
        dlf_searching ||
        dnf_searching ||
        current_searching ||
        prefix_searching ||
        suffix_searching;
      else infoSection
    ">
          <div class="pla_card no-card mobile spacing">
            <pla-initial-section [header]="'Dateless - Letters First'" [results]="initialResults.dateless_letter_first"
              [searching]="dlf_searching" [resultChange]="dlf_resultsChange"></pla-initial-section>
            <pla-initial-section [header]="'Dateless - Numbers First'" [results]="initialResults.dateless_number_first"
              [searching]="dnf_searching" [resultChange]="dnf_resultsChange"></pla-initial-section>
            <pla-initial-section [header]="'Current Style'" [results]="initialResults.current"
              [searching]="current_searching" [resultChange]="c_resultsChange"></pla-initial-section>
            <pla-initial-section [header]="'Prefix Style'" [results]="initialResults.prefix"
              [searching]="prefix_searching" [resultChange]="p_resultsChange"></pla-initial-section>
            <pla-initial-section [header]="'Suffix Style'" [results]="initialResults.suffix"
              [searching]="suffix_searching" [resultChange]="s_resultsChange"></pla-initial-section>
          </div>
        </div>

        <ng-template #infoSection>
          <!-- Page steps -->
          <div class="d-none page-steps">
            <pla-page-steps [steps]="pageSteps"></pla-page-steps>
          </div>

          <div class="pla_card no-card no-top no-p-top mobile spacing padding">
            <div class="px-4">
              <div class="text-title heading">
                <h2 class="border-bottom mb-1">
                  <b class="strong">Create A Monogram</b>
                </h2>
              </div>
              <div class="text-section">
                <div class="text-paragraph">
                  <p>
                    Initials are the most popular option for personalising a
                    registration and its never been easier to find your perfect
                    initial plate. Simply enter up to 3 letters and click search
                    and
                    <pla-glossary-term-word [term]="'PLATO'" [text]="'PLATO'"></pla-glossary-term-word>
                    will return you the best registrations to showcase your
                    initials.
                  </p>
                </div>
              </div>

              <div class="text-title heading mt-3">
                <h2 class="border-bottom mb-1">
                  <b class="strong">Notify</b>
                </h2>
              </div>
              <div class="text-section">
                <div class="text-paragraph">
                  <p class="indent">
                    If the particular number you wanted to go alongside your
                    initials is not available then why not take advantage of our
                    free `Notify` service. Once enabled you will be notified first
                    giving you the best chance to snap it up!
                  </p>
                </div>

                <!-- TODO: FEEDBACK -->
                <div class="text-paragraph d-none">
                  <div class="faq-item">
                    <mat-icon>help_outline</mat-icon>
                    <h4>
                      I want a particular number to go alongside my initials but
                      it’s not there?
                    </h4>
                  </div>
                  <p class="indent">
                    This may mean that the number was not part of the series of
                    has already been sold. Toggle to ‘See All’ and if you see the
                    registration, you want click Notify and should it ever come
                    back to the market you can be informed!
                  </p>

                  <div class="faq-item">
                    <mat-icon>help_outline</mat-icon>
                    <h4>Can I put any of these registrations on my vehicle?</h4>
                  </div>
                  <p class="indent">
                    You are not allowed to make a vehicle appear younger by
                    transferring a more recent registration. Simply click on the
                    registration to see the minimum age of a vehicle needed for
                    that registration.
                  </p>
                </div>
              </div>
            </div>

            <!-- FEATURED LISTING -->
            <div class="featured-container mt-3">
              <div class="px-4">
                <h2 class="border-bottom mb-1">
                  <b class="strong">Number 1 Plates</b>
                </h2>
              </div>
              <div>
                <pla-featured-plates [featureType]="initialFeatured" [showTitle]="false"></pla-featured-plates>
              </div>
            </div>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>