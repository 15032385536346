<div class="position-relative text-dark w-100 pointer {{ containerClass }}">
  <div
    class="w-100 overflow-hidden d-flex align-items-center justify-content-center gap-2 px-2 py-2 {{ baseClass }} {{ (config && open) ? openedClass : closedClass }}"
    style="cursor: pointer;" (click)="ToggleOpen()">
    <small class="fs-6 text-decoration-none text-dark text-center d-block my-0">{{ config.title }}</small>
    <mat-icon *ngIf="config.icon" class="small">{{config.icon}}</mat-icon>
  </div>

  <!-- OPTIONS -->
  <div *ngIf="config && open"
    class="{{ optionClass }} w-100 position-absolute start-0 end-0 bottom-0 translate-bottom bg-light shadow-sm"
    style="z-index: 100;">
    <ng-container *ngFor="let option of VisibleOptions(config.options)">
      <div class="border-top px-3 py-3 d-flex align-items-center justify-content-start gap-2 bg-white"
        style="cursor: pointer" (click)="SelectValue(option)">
        <div class="p-1 rounded-circle border border-2" [ngClass]="{
          'border-dark': option.value == selectedValue.value
        }">
          <div [ngClass]="{
            'border-dark': option.value == selectedValue.value,
            'bg-dark': option.value == selectedValue.value,
            'bg-transparent': option.value != selectedValue.value
          }" class="rounded-circle" style="width: 7px; height: 7px"></div>
        </div>
        <p class="mb-0 fs-6 text-start" [ngClass]="{
          'fw-normal': option.value == selectedValue.value,
          'fw-light': option.value != selectedValue.value,
          'text-muted': option.value != selectedValue.value,
          'text-dark': option.value == selectedValue.value
        }">
          {{ option.view }}
        </p>
      </div>
    </ng-container>
  </div>
</div>