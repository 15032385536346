import { ViewportScroller } from '@angular/common';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

export enum StickyLinkType {
  IN_PAGE,
  OUT_OF_PAGE
}

export class StickyLink {
  constructor(
    public viewName: string,
    public anchor: string,
    public active: boolean,
    public linkType: StickyLinkType = StickyLinkType.IN_PAGE,
    public hidden: boolean = false
  ) { }
}

export class StickyLinkGroup {
  constructor(
    public links: StickyLink[] = [],
  ) { }
}

export class ConfigStyle {
  public backgroundColour: string;
  public textColour: string;
  public borderColour: string;
}

export class ConfigMeta {
  public sticky: boolean;
  public backgroundColour: string;
  public defaultStyle: ConfigStyle;
  public selectedStyle: ConfigStyle;
}

export const DEFAULT_STICKY_CONFIG: ConfigMeta = {
  sticky: true,
  backgroundColour: '#fff',
  defaultStyle: {
    backgroundColour: '#dee2e6',
    textColour: '#212529',
    borderColour: '#dee2e6'
  },
  selectedStyle: {
    backgroundColour: '#0a6077',
    textColour: '#fff',
    borderColour: '#0a6077'
  }
}

export class StickyConfig {
  constructor(
    public groups: StickyLinkGroup[] = [],
    public reScroll: EventEmitter<void> = new EventEmitter<void>(),
    public metaData: ConfigMeta = DEFAULT_STICKY_CONFIG
  ) { }
}

@Component({
  selector: 'pla-sticky-tabs',
  templateUrl: './sticky-tabs.component.html',
  styleUrls: ['./sticky-tabs.component.scss'],
})
export class StickyTabsComponent implements OnInit {
  @Input() public config: StickyConfig = new StickyConfig();
  @Input() public classes: string = 'py-2';
  public groups: StickyLinkGroup[] = [];

  constructor(private viewportScroller: ViewportScroller, private router: Router) { }

  ngOnInit(): void {
    this.groups = this.config.groups;
    this.config.reScroll.subscribe(() => {
      var groupWithActiveLink = this.groups.find(g => g.links.find((l) => l.active));
      if (groupWithActiveLink === undefined || groupWithActiveLink === null) return;
      var link = groupWithActiveLink.links.find(l => l.active);
      if (link === undefined || link === null) return;
      this.viewportScroller.scrollToAnchor(link.anchor);
    });
  }

  public ChangeLink(link: StickyLink): void {
    this.groups = this.groups.map(g => {
      g.links = g.links.map((l) => {
        l.active = l.anchor == link.anchor;
        return l;
      });
      return g;
    });


    if (link.linkType === StickyLinkType.IN_PAGE) {
      this.viewportScroller.scrollToAnchor(link.anchor);
    } else {
      this.router.navigate([link.anchor]);
    }
  }
}
