<div class="nsi px-2" [ngClass]="{'focused': focused || (searchInput !== '' && searchInput != null) }"
    (click)="FocusElement()">
    <div for="#search-input" class="changing-text">
        <pla-text-changer [texts]="searchTexts" [customClasses]="'fs-mb-11 fs-md-14 fw-light'" [loopText]="true"
            [duration]="1.5"></pla-text-changer>
    </div>
    <div class="custom-input position-relative w-100">
        <input id="search-input" style="color: transparent !important"
            class="bg-transparent rounded-0 flex-fill border-0 py-0 px-0 m-0 w-100 fw-bolder text-start"
            (ngModelChange)="searchInput = $event.toUpperCase()" [ngModel]="searchInput" (focus)="SetFocus(true)"
            (blur)="SetFocus(false)" (keydown)="EnterSubmit($event)">
        <span *ngIf="searchInput !== '' && searchInput != null" style="pointer-events: none; text-wrap: nowrap;"
            class="custom-value position-absolute top-50 start-0 end-0 w-100 translate-middle-y fw-bolder">{{searchInput}}</span>
        <span *ngIf="searchInput === '' || searchInput == null" style="pointer-events: none; text-wrap: nowrap;"
            class="custom-placeholder position-absolute top-50 start-0 end-0 w-100 translate-middle-y fw-bolder">{{placeholder}}</span>
    </div>
</div>