<div class="{{classes}}">
  <div class="bg-transparent pt-3 overflow-hidden shadow-sm">
    <div *ngIf="showText">
      <div *ngIf="mainLoader" class="px-2 pb-4">
        <pla-text-changer [texts]="loadingTexts" [duration]="4"></pla-text-changer>
      </div>
      <div *ngIf="!mainLoader" class="d-flex align-items-center justify-content-between w-100 pb-3">
        <small class="mx-2 text-muted align-self-center text-center fw-bold fs-6 w-100 d-block">
          Finalising...
        </small>
      </div>
    </div>
    <pla-custom-loader [progress]="progress" [barColour]="'#4A0404'">
      <div style="transform: translateY(5px);">
        <svg xmlns="http://www.w3.org/2000/svg" width="45.731" height="13.041" viewBox="0 0 45.731 13.041">
          <g id="Group_2262" data-name="Group 2262" transform="translate(-150.606 -725.354)">
            <g id="Path_1153" data-name="Path 1153" transform="translate(155.741 724.508)" fill="none">
              <path d="M15.82,1.221A67.212,67.212,0,0,1,23.9,4.991L8.353,5.343,4.159,1.883S12.537.088,15.82,1.221Z"
                stroke="none" />
              <path
                d="M 12.76473331451416 1.546018838882446 C 10.35547637939453 1.546018838882446 7.455575942993164 1.98809814453125 5.752956390380859 2.290694236755371 L 8.597404479980469 4.637447834014893 L 21.16823768615723 4.352589130401611 C 19.52127075195312 3.523189067840576 17.32717895507812 2.481335401535034 15.59203243255615 1.882668972015381 C 14.9445629119873 1.659288883209229 13.99332237243652 1.546018838882446 12.76473331451416 1.546018838882446 M 12.76473522186279 0.8460192680358887 C 13.95259284973145 0.8460192680358887 15.03283596038818 0.9492454528808594 15.82033252716064 1.220949172973633 C 19.10336303710938 2.353668928146362 23.90118217468262 4.990839004516602 23.90118217468262 4.990839004516602 L 8.352853775024414 5.34316873550415 L 4.159183502197266 1.883269071578979 C 4.159183502197266 1.883269071578979 9.000465393066406 0.8460192680358887 12.76473522186279 0.8460192680358887 Z"
                stroke="none" fill="#4A0404" />
            </g>
            <g id="Group_2254" data-name="Group 2254" transform="translate(150.606 726.174)">
              <path id="Path_1152" data-name="Path 1152"
                d="M1.413,2.4s.21-.307-.272-.727S10.5-.4,10.5-.4s3.6,2.534,3.843,2.531c5.295-.084,19.081-.14,24.853,1.114a22.352,22.352,0,0,1,3.8,1.4c1.68.854,2.979,1.681,2.923,2.017s-.886.421-1.064.737c-.006.049-.331,1.534-.331,1.534l.727.534-1.362.524H40.948a6.855,6.855,0,0,0,.273-2.778,3.828,3.828,0,0,0-1.9-2.728,4.212,4.212,0,0,0-5.487.967c-1.25,1.757-.584,4.538-.584,4.538s-10.635-.143-19.743-.34c0,0,.838-3.648-2.117-5.4a4.341,4.341,0,0,0-4.8.393C3.963,6.469,5.378,9.65,5.378,9.65A15.506,15.506,0,0,1,1.3,8.52c-2.417-.987-.158-1.218-.158-3.241A10.658,10.658,0,0,1,1.413,2.4Z"
                transform="translate(-0.183 0.396)" fill="#4A0404" />
              <g id="Ellipse_806" data-name="Ellipse 806" transform="translate(5.309 4.368)" fill="none"
                stroke="#4A0404" stroke-width="0.7">
                <circle cx="3.927" cy="3.927" r="3.927" stroke="none" />
                <circle cx="3.927" cy="3.927" r="3.577" fill="none" />
              </g>
              <g id="Ellipse_808" data-name="Ellipse 808" transform="translate(33.308 4.625)" fill="none"
                stroke="#4A0404" stroke-width="0.7">
                <circle cx="3.705" cy="3.705" r="3.705" stroke="none" />
                <circle cx="3.705" cy="3.705" r="3.355" fill="none" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </pla-custom-loader>
  </div>
</div>