import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { StickyConfig, StickyLinkGroup, StickyLink, DEFAULT_STICKY_CONFIG, StickyLinkType, ConfigMeta } from '../sticky-tabs/sticky-tabs.component';

@Component({
  selector: 'pla-main-nav-chips',
  templateUrl: './main-nav-chips.component.html',
  styleUrls: ['./main-nav-chips.component.scss']
})
export class MainNavChipsComponent implements OnInit {

  @Input() public defaultConfig: boolean = true;
  @Input() public config: ConfigMeta = {
    ...DEFAULT_STICKY_CONFIG,
    sticky: false,
    backgroundColour: 'rgba(13,13,13,0.23)',
    defaultStyle: {
      borderColour: '#fff',
      textColour: '#fff',
      backgroundColour: 'transparent'
    },
    selectedStyle: {
      borderColour: '#fff',
      textColour: '#2f2f2f',
      backgroundColour: '#fff'
    }
  };

  public stickyLinkConfig: StickyConfig = new StickyConfig([
    new StickyLinkGroup(
      [
        new StickyLink('Buy', '/buy', true, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Initials', '/initials-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Dateless', '/dateless-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Build-Your-Own', '/byo-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Names', '/names', false, StickyLinkType.OUT_OF_PAGE),
      ],
    ), new StickyLinkGroup([
      new StickyLink('Sell', '/sell', false, StickyLinkType.OUT_OF_PAGE)
    ]), new StickyLinkGroup([
      new StickyLink('Price Comparison', '/compare', false, StickyLinkType.OUT_OF_PAGE)
    ]), new StickyLinkGroup([
      new StickyLink('Market Watch', 'market-watch', false, StickyLinkType.IN_PAGE)
    ])],
    new EventEmitter<void>(),
    this.config
  );

  constructor() { }

  ngOnInit(): void {
    this.stickyLinkConfig = { ...this.stickyLinkConfig, metaData: this.defaultConfig === true ? DEFAULT_STICKY_CONFIG : this.config };
    this.SetActivePage();
  }

  private SetActivePage(): void {
    const path = window.location.pathname;
    this.stickyLinkConfig.groups = this.stickyLinkConfig.groups.map(g => {
      g.links = g.links.map(l => {
        l.active = path.startsWith(l.anchor);
        return l;
      });
      return g;
    })
  }

}
