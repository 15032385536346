<div id="sticky-bby" [ngClass]="{'position-sticky': config.metaData.sticky }"
  [ngStyle]="{ 'background': config.metaData.backgroundColour }" style="top: 54px; z-index: 90;">
  <div class="d-flex align-items-stretch justify-content-start border-bottom gap-3 gap-md-3 {{classes}} px-2 px-md-1"
    style="overflow-x: auto;">
    <ng-container *ngFor="let group of groups; let last = last">
      <ng-container *ngFor="let link of group.links">
        <div *ngIf="!link.hidden"
          class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer"
          [style.background]="link.active ? config.metaData.selectedStyle.backgroundColour : config.metaData.defaultStyle.backgroundColour"
          [style.borderColor]="link.active ? config.metaData.selectedStyle.borderColour : config.metaData.defaultStyle.borderColour"
          style="cursor: pointer; border: 1px solid;" (click)="ChangeLink(link)">
          <small class="my-0 d-block lh-sm {{
          link.active ? 'fs-md-14' : 'fs-md-14'
        }}"
            [style.color]="link.active ? config.metaData.selectedStyle.textColour : config.metaData.defaultStyle.textColour">{{
            link.viewName }}</small>
        </div>
      </ng-container>
      <div *ngIf="!last" style="border-color: #dee2e6 !important;" class="border-start border-1"></div>
    </ng-container>
  </div>
</div>