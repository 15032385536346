<button *ngIf="plate.available && plate.price > 0"
    class="px-0 bg-transparent border-0 d-flex flex-column align-content-center justify-content-center text-center"
    (click)="handleFavouriteRegistration()">
    <mat-progress-spinner *ngIf="loading" class="spinner black mx-auto" mode="indeterminate" [color]="'#000'"
        [diameter]="24"></mat-progress-spinner>
    <mat-icon *ngIf="!loading" [ngStyle]="{
        'color': plate.favourite ? '#E87676' : '#2f2f2f'
    }" class="d-block mx-auto fs-md-24 fs-mb-20">{{ plate.favourite ?
        'favorite' :
        'favorite_border'
        }}</mat-icon>
    <small *ngIf="showText" class="d-none d-md-block fs-md-13 mx-auto">{{ plate.favourite ?
        'Saved' :
        'Favourite'
        }}</small>
</button>

<button *ngIf="!plate.available || plate.price <= 0"
    class="px-0 bg-transparent border-0 d-flex flex-column align-content-center justify-content-center text-center"
    (click)="handleNotifyRegistration()">
    <mat-progress-spinner *ngIf="loading" class="spinner black mx-auto" mode="indeterminate" [color]="'#000'"
        [diameter]="24"></mat-progress-spinner>
    <mat-icon *ngIf="!loading" [ngStyle]="{
        'color': plate.notify ? '#0a6077' : '#2f2f2f'
    }" class="d-block mx-auto fs-md-24 fs-mb-20">{{ plate.notify ?
        'notifications' :
        'notifications_none'
        }}</mat-icon>
    <small *ngIf="showText" class="d-none d-md-block fs-md-13 mx-auto">{{ plate.notify ? 'Notified' : 'Notify'
        }}</small>
</button>